import React, { useState, useEffect } from 'react';
import { Select, Space } from 'antd';
import './../css/comparison.css'; // Import your custom CSS file
import collegeTeamToAbbr from './../utils/collegeTeamToAbbr';
import collegeTeamColors from './../utils/collegeTeamColors';
import collegeTeamFullNameColors from './../utils/collegeTeamFullNameColors';

const maxValues = {
    QB: {
        "PPG": 32.2,
        "PPPG": 30.0,
        "RPPG": 10.8,
        "aY/A": 10.5,
        "PASSTD%": 9.3,
        YPA: 9.6,
        "Pass Attempts": 45.4,
        "CARRY%": 36.5,
        YPTRA: 2.32,
        "RUSHTD%": 23.1
    },
    RB: {
        "PPG": 29.2,
        "Rush PPG": 19.2,
        "Receive PPG": 15.0,
        "CARRY%": 85.0,
        YPTRA: 4.31,
        "RUSH TD MS": 100.0,
        "TARGET SHARE": 22.6,
        YPTPA: 1.66
    },
    WR: {
        "PPG": 25.7,
        "TARGET SHARE": 39.6,
        YPTPA: 3.38,
        "TARGET RATE": 41.9,
        YPRR: 3.82
    },
    TE: {
        "PPG": 24.2,
        "TARGET SHARE": 26.3,
        YPTPA: 2.52,
        "TARGET RATE": 37.5,
        YPRR: 3.20
    }
};

// Team colors mapping (abbreviation => RGB color)
const teamColors = {
    ARI: 'rgb(151, 35, 63)',
    ATL: 'rgb(167, 25, 48)',
    BAL: 'rgb(26, 25, 95)',
    BUF: 'rgb(0, 51, 141)',
    CAR: 'rgb(0, 133, 202)',
    CHI: 'rgb(11, 22, 42)',
    CIN: 'rgb(251, 79, 20)',
    CLE: 'rgb(49, 29, 0)',
    DAL: 'rgb(0, 53, 148)',
    DEN: 'rgb(0, 34, 68)',
    DET: 'rgb(0, 118, 182)',
    GB: 'rgb(24, 48, 40)',
    HOU: 'rgb(3, 32, 47)',
    IND: 'rgb(0, 44, 95)',
    JAX: 'rgb(0, 103, 120)',
    KC: 'rgb(227, 24, 55)',
    LAC: 'rgb(0, 128, 198)',
    LAR: 'rgb(0, 53, 148)',
    LV: 'rgb(0, 0, 0)',
    MIA: 'rgb(0, 142, 151)',
    MIN: 'rgb(79, 38, 131)',
    NE: 'rgb(0, 34, 68)',
    NO: 'rgb(211, 188, 141)',
    NYG: 'rgb(1, 35, 82)',
    NYJ: 'rgb(18, 87, 64)',
    PHI: 'rgb(0, 76, 84)',
    PIT: 'rgb(16, 24, 32)',
    SF: 'rgb(170, 0, 0)',
    SEA: 'rgb(0, 34, 68)',
    TB: 'rgb(213, 10, 10)',
    TEN: 'rgb(12, 35, 64)',
    WAS: 'rgb(63, 16, 16)',
};
const statKeyMap = {
    "PPG": "ppg",
    "CARRY%": "carrypercent",
    "TARGET SHARE": "targetshare",
    'RUSHTD%': 'rushtdpercent',
    "YPTPA": "yptpa",
    "YPTRA": "yptra",
    "RUSH TD MS": "rushtdms",
    "TARGET RATE": "targetrate",
    "YPRR": "yprr",
    "RPPG": "rppg",
    "aY/A": "aya",
    "PASSTD%": "passtdpercent",
    "YPA": "ypa",
    "PPPG": "pppg"
};

const getTeamColor = (teamAbbreviation, isNFL) => {
    if (isNFL) {
        // Return NFL team color or default
        return teamColors[teamAbbreviation] || '#232c30';
    } else {
        
            return collegeTeamFullNameColors[teamAbbreviation] || '#232c30'; // Default if no color

    }
};

// Function to determine which stats to compare based on player positions
const getComparisonStats = (pos1, pos2) => {
    if (pos1 === 'QB' && pos2 === 'QB') return ["PPG", "PPPG", "RPPG", "aY/A", "PASSTD%", "YPA", "CARRY%", "YPTRA", "RUSHTD%"];
    if (pos1 === 'QB' && pos2 === 'RB' || pos1 === 'RB' && pos2 === 'QB') return ["PPG"];
    if (pos1 === 'QB' && pos2 === 'WR' || pos1 === 'WR' && pos2 === 'QB') return ["PPG"];
    if (pos1 === 'QB' && pos2 === 'TE' || pos1 === 'TE' && pos2 === 'QB') return ["PPG"];
    if (pos1 === 'RB' && pos2 === 'RB') return ["PPG", "CARRY%", "YPTRA", "RUSH TD MS", "TARGET SHARE", "YPTPA"];
    if (pos1 === 'RB' && pos2 === 'WR' || pos1 === 'WR' && pos2 === 'RB') return ["PPG", "TARGET SHARE", "YPTPA"];
    if (pos1 === 'RB' && pos2 === 'TE' || pos1 === 'TE' && pos2 === 'RB') return ["PPG", "TARGET SHARE", "YPTPA"];
    if (pos1 === 'WR' && pos2 === 'WR') return ["PPG", "TARGET SHARE", "YPTPA", "TARGET RATE", "YPRR"];
    if (pos1 === 'WR' && pos2 === 'TE' || pos1 === 'TE' && pos2 === 'WR') return ["PPG", "TARGET SHARE", "YPTPA", "TARGET RATE", "YPRR"];
    if (pos1 === 'TE' && pos2 === 'TE') return ["PPG", "TARGET SHARE", "YPTPA", "TARGET RATE", "YPRR"];
    return []; // Default to an empty array if no matching positions
};



const ComparisonBars = ({ bars, direction, team, isNFL }) => {
    // Get the team color using the team abbreviation
    const teamColor = getTeamColor(team, isNFL);

    // State to manage bar widths (start at 0)
    const [barWidths, setBarWidths] = useState(bars.map(() => 0));

    // Trigger the bar fill animation after the component mounts
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setBarWidths(bars.map(bar => bar.value));  // Update to final bar width
        }, 100); // Delay to ensure it's after the initial render

        return () => clearTimeout(timeoutId);  // Clean up the timeout
    }, [bars]);  // Rerun effect when the bars data changes

    return (
        <div className={`comparison-bars-container ${direction}`}>
            {bars.map((bar, index) => (
                <div key={index} className={`comparison-bar ${direction}-bar`}>
                    <div
                        className="bar-fill"
                        style={{
                            width: `${barWidths[index]}%`,  // Use the dynamic width state
                            backgroundColor: teamColor,  // Set team color dynamically
                            transition: 'width 1.5s ease-in-out'  // Smooth growing effect for bar fill
                        }}
                    >
                        <span className={`bar-label ${direction}-label`}>{bar.label}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};


const ComparisonWidget = ({ firstPlayer, secondPlayer, isNFL = true }) => {
    const firstPlayerPosition = firstPlayer.player_info.position;
    const secondPlayerPosition = secondPlayer.player_info.position;

    // Determine which stats to display based on player positions
    const statsToCompare = getComparisonStats(firstPlayerPosition, secondPlayerPosition);

    // State to track the selected years
    const [selectedYear1, setSelectedYear1] = useState(["CAREER"]);
    const [selectedYear2, setSelectedYear2] = useState(["CAREER"]);
    const [firstPlayerBars, setFirstPlayerBars] = useState([]);
    const [secondPlayerBars, setSecondPlayerBars] = useState([]);

    // Handle change for multi-select, ensuring "CAREER" is not mixed with other options
    const handleYearChange = (selectedYears, setSelectedYears) => {
        if (selectedYears.includes("CAREER") && selectedYears.length > 1) {
            setSelectedYears(["CAREER"]); // Restrict to "CAREER" only
        } else {
            setSelectedYears(selectedYears);
        }
    };

    // Function to find the stats for a specific year
    const getStatsForYear = (player, year) => {
        return player.stats.find(stat => String(stat.calendaryear) === String(year)) || {};
    };

    // Function to determine if a year has all zero stats
    const isAllZeroStats = (stats, statsToCompare) => {
        return statsToCompare.every(statKey => {
            const actualStatKey = statKeyMap[statKey];
            const value = stats[actualStatKey] || 0;
            return value === 0;
        });
    };

    const getAvailableYears = (player, isNFL) => {
        return player.stats
            .filter(stat => {
                if (isNFL) {
                    return !stat.iscfb; // Include only non-CFB years if isNFL is true
                } else {
                    return stat.iscfb; // Include only CFB years if isNFL is false
                }
            })
            .filter(stat => {
                const yearStats = getStatsForYear(player, stat.calendaryear);
                return !isAllZeroStats(yearStats, statsToCompare); // Exclude years with all zero stats
            })
            .map(stat => ({
                label: `${stat.calendaryear} (Year ${stat.yearnum})`, // Append yearnum
                value: String(stat.calendaryear)
            }))
            .sort((a, b) => a.value - b.value); // Sort years numerically
    };

    // Function to determine the max value for a given stat based on two positions
    // Function to determine the max value for a given stat based on two positions
    const getMaxValueForStat = (statKey, position1, position2) => {
        const pos1Max = maxValues[position1]?.[statKey] || 0;
        const pos2Max = maxValues[position2]?.[statKey] || 0;
        const maxValue = Math.max(pos1Max, pos2Max); // Use the larger of the two, or 0 if neither is defined

        // Debugging log to print the statKey, max values for both positions, and the final max value

        return maxValue;
    };

    // Helper function to get the arrow color and glow effect
    const getArrowStyle = (firstValue, secondValue, side) => {
        const color = side === 'left' ? (firstValue > secondValue ? 'green' : 'gray') : (secondValue > firstValue ? 'green' : 'gray');
        const textShadow = color === 'green' ? '0 0 8px green, 0 0 15px green' : 'none'; // Add glow effect if green
        return { color, textShadow };
    };


    const generateBarsData = (player, selectedYears, position1, position2) => {
        // Single year case
        if (selectedYears.length === 1 && selectedYears[0] !== "CAREER") {
            const year = selectedYears[0];
            const stats = getStatsForYear(player, year);

            return statsToCompare.map(statKey => {
                const actualStatKey = statKeyMap[statKey];
                const value = stats[actualStatKey] || 0;
                const maxValue = getMaxValueForStat(statKey, position1, position2); // Get max value for this stat
                const percentage = (value / maxValue) * 100; // Calculate percentage fill
                return {
                    label: value.toFixed(2), // Pass the actual stat value as the label
                    value: percentage || 0 // Use 0 if no max value or stat is not found
                };
            });
        }

        // "CAREER" case: average across all years
        if (selectedYears.length === 1 && selectedYears[0] === "CAREER") {
            const allStats = player.stats; // Get all years' stats

            return calculateAverages(allStats, position1, position2);
        }

        // Multiple years selected case
        if (selectedYears.length > 1) {
            const selectedStats = player.stats.filter(stat => selectedYears.includes(String(stat.calendaryear)));

            return calculateAverages(selectedStats, position1, position2);
        }

        return [];
    };

    // Helper function to calculate averages with zero-check for consecutive years
    // Helper function to calculate averages and skip zero years entirely
    const calculateAverages = (statsArray, position1, position2) => {
        // Initialize a sum object to accumulate stats
        const sumStats = {};
        let numValidYears = 0;  // Count the valid (non-zero) years

        statsArray.forEach(stat => {
            let isYearZero = true; // Flag to check if all stats in this year are zero

            statsToCompare.forEach(statKey => {
                const actualStatKey = statKeyMap[statKey];
                const value = stat[actualStatKey] || 0;

                if (value !== 0) {
                    isYearZero = false; // This year is not a zero year
                }

                if (!sumStats[statKey]) {
                    sumStats[statKey] = 0; // Initialize to 0 if not present
                }

                sumStats[statKey] += value; // Accumulate values
            });

            // Only count this year if it has non-zero stats
            if (!isYearZero) {
                numValidYears += 1; // Increment valid years count
            }
        });

        // Calculate averages by dividing each stat sum by the number of valid years
        const avgStats = statsToCompare.map(statKey => {
            const averageValue = numValidYears > 0 ? sumStats[statKey] / numValidYears : 0;
            const maxValue = getMaxValueForStat(statKey, position1, position2); // Get max value for this stat
            const percentage = (averageValue / maxValue) * 100; // Calculate percentage fill

            return {
                label: averageValue.toFixed(2), // Pass the average stat value as the label
                value: percentage || 0 // Use 0 if no max value or stat is not found
            };
        });

        return avgStats;
    };

    // Effect to reset selected years to "CAREER" and update bars when players change
    useEffect(() => {
        // Reset selected years to "CAREER" when players change
        setSelectedYear1(["CAREER"]);
        setSelectedYear2(["CAREER"]);

        // Update the bars for the new players, using "CAREER" as the default year
        setFirstPlayerBars(generateBarsData(firstPlayer, ["CAREER"], firstPlayer.player_info.position, secondPlayer.player_info.position));
        setSecondPlayerBars(generateBarsData(secondPlayer, ["CAREER"], secondPlayer.player_info.position, firstPlayer.player_info.position));
    }, [firstPlayer, secondPlayer]); // Only runs when firstPlayer or secondPlayer changes

    // Effect to update bars when the selected years change
    useEffect(() => {
        // Update the bars when years are changed
        setFirstPlayerBars(generateBarsData(firstPlayer, selectedYear1, firstPlayer.player_info.position, secondPlayer.player_info.position));
        setSecondPlayerBars(generateBarsData(secondPlayer, selectedYear2, secondPlayer.player_info.position, firstPlayer.player_info.position));
    }, [selectedYear1, selectedYear2, firstPlayer, secondPlayer]);
    // Extract years from the player's stats

    const firstPlayerYears = [{ label: "CAREER", value: "CAREER" }, ...getAvailableYears(firstPlayer, isNFL)];
    const secondPlayerYears = [{ label: "CAREER", value: "CAREER" }, ...getAvailableYears(secondPlayer, isNFL)];



    return (
        <div className="comparison-widget">
            <div className="comparison-row">
                <div className="comparison-column left-column">
                    <div className="player-info-comparison">
                        <p className="first-name">{firstPlayer.player_info.first_name}</p>
                        <p className="last-name">{firstPlayer.player_info.last_name}</p>
                        <div className="position-team" style={{ fontWeight: 'bold' }}>
                            <p className="position-compare">{firstPlayer.player_info.position}</p>
                            <p
                                className="team-compare"
                                style={{
                                    backgroundColor: isNFL
                                        ? getTeamColor(firstPlayer.player_info.team, true)
                                        : collegeTeamFullNameColors[firstPlayer.player_info.college],
                                }}
                            >
                                {isNFL
                                    ? firstPlayer.player_info.team
                                    : collegeTeamToAbbr[firstPlayer.player_info.college] || 'Unknown'}
                            </p>

                        </div>
                    </div>
                    <div className="select-wrapper">
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Select year(s)"
                            value={selectedYear1}
                            onChange={(value) => handleYearChange(value, setSelectedYear1)}
                            options={firstPlayerYears}
                        />
                    </div>
                    <ComparisonBars bars={firstPlayerBars} direction="left" team={isNFL ? firstPlayer.player_info.team : firstPlayer.player_info.college} isNFL={isNFL}
                    />
                </div>

                <div className="comparison-column middle-column">
                    {/* Stat Labels in the Middle Column */}
                    {statsToCompare.map((statKey, index) => {
                        const firstPlayerStat = firstPlayerBars[index]?.value || 0;
                        const secondPlayerStat = secondPlayerBars[index]?.value || 0;

                        return (
                            <div key={statKey} className="stat-label">
                                <span style={getArrowStyle(firstPlayerStat, secondPlayerStat, 'left')}>{'<'}</span>
                                <span style={{ margin: '0 10px' }}>{statKey.toUpperCase()}</span>
                                <span style={getArrowStyle(firstPlayerStat, secondPlayerStat, 'right')}>{'>'}</span>
                            </div>
                        );
                    })}
                </div>

                <div className="comparison-column right-column">
                    <div className="player-info-comparison">
                        <p className="first-name">{secondPlayer.player_info.first_name}</p>
                        <p className="last-name">{secondPlayer.player_info.last_name}</p>
                        <div className="position-team" style={{ fontWeight: 'bold' }}>
                            <p className="position-compare">{secondPlayer.player_info.position}</p>
                            <p
                                className="team-compare"
                                style={{
                                    backgroundColor: isNFL
                                        ? getTeamColor(secondPlayer.player_info.team, true)
                                        : collegeTeamFullNameColors[secondPlayer.player_info.college],
                                }}
                            >
                                {isNFL
                                    ? secondPlayer.player_info.team
                                    : collegeTeamToAbbr[secondPlayer.player_info.college] || 'Unknown'}
                            </p>
                        </div>
                    </div>
                    <div className="select-wrapper">
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Select year(s)"
                            value={selectedYear2}
                            onChange={(value) => handleYearChange(value, setSelectedYear2)}
                            options={secondPlayerYears}
                        />
                    </div>
                    <ComparisonBars
                        bars={secondPlayerBars}
                        direction="right"
                        team={isNFL ? secondPlayer.player_info.team : secondPlayer.player_info.college}
                        isNFL={isNFL}
                    />                </div>
            </div>
        </div>
    );
};

export default ComparisonWidget;





