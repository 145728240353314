import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Input, Modal, Space, Table } from "antd";
import React, { useState } from "react";
import "../css/playertable.css"; // Reuse PlayerTable CSS

const RostershipTable = ({ rostershipData }) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [pagination] = useState({ pageSize: 50, current: 1 });

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSearchText("");
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchText("");
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const getUniqueValues = (data, key) => {
    return [...new Set(data.map((item) => item[key]))].map((value) => ({
      text: value,
      value,
    }));
  };

  const filteredData = rostershipData
    ? Object.keys(rostershipData)
        .map((playerName) => {
          const playerDetails = rostershipData[playerName];
          const percentage = (
            (playerDetails.numberOfOccurances / playerDetails.numberOfLeagues) *
            100
          ).toFixed(2);

          return {
            key: playerName,
            playerName: playerName,
            position: playerDetails.position,
            team: playerDetails.team,
            tier: playerDetails.tier,
            type: playerDetails.type,
            occurrences: playerDetails.numberOfOccurances,
            percentage: percentage,
            leagues: playerDetails.leagues,
          };
        })
        .filter(
          (item) =>
            item.playerName.toLowerCase().includes(searchText.toLowerCase()) ||
            item.team.toLowerCase().includes(searchText.toLowerCase())
        )
    : [];

  const columns = [
    {
      title: "SHRS",
      dataIndex: "occurrences",
      key: "occurrences",
      width: "60px",
      sorter: (a, b) => a.occurrences - b.occurrences,
      sortOrder: sortedInfo.columnKey === "occurrences" && sortedInfo.order,
      defaultSortOrder: "descend", // Automatically sort in descending order by default
    },
    {
      title: "Name",
      dataIndex: "playerName",
      key: "playerName",
      width: "110px",
      render: (text) => {
        const parts = text.split(" ");
        const firstName = parts.shift(); // Get the first name
        const lastName = parts.join(" "); // Join the rest as the last name
        return <span>{`${firstName.charAt(0)}. ${lastName}`}</span>;
      },
    },
    {
      title: "Pos",
      dataIndex: "position",
      key: "position",
      width: "90px",
      filters: getUniqueValues(filteredData, "position"),
      filteredValue: filteredInfo.position || null,
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#000" : "#ccc" }} />
      ),
      onFilter: (value, record) => record.position?.includes(value),
      sorter: (a, b) => a.position.localeCompare(b.position),
      sortOrder: sortedInfo.columnKey === "position" && sortedInfo.order,
    },
    {
      title: "TM",
      dataIndex: "team",
      key: "team",
      width: "90px",
      filters: getUniqueValues(filteredData, "team"),
      filteredValue: filteredInfo.team || null,
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#000" : "#ccc" }} />
      ),
      onFilter: (value, record) => record.team?.includes(value),
      sorter: (a, b) => a.team.localeCompare(b.team),
      sortOrder: sortedInfo.columnKey === "team" && sortedInfo.order,
    },
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
      width: "180px",
      filters: [
        { text: "UNTOUCHABLE", value: "UNTOUCHABLE" },
        { text: "CORNERSTONE", value: "CORNERSTONE" },
        { text: "FOUNDATION", value: "FOUNDATION" },
        { text: "BASE", value: "BASE" },
        { text: "DEPTH", value: "DEPTH" },
        { text: "CLOGGER", value: "CLOGGER" },
        { text: "", value: "" },
      ],
      filteredValue: filteredInfo.tier || null,
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#000" : "#ccc" }} />
      ),
      onFilter: (value, record) => record.tier === value,
      sorter: (a, b) => (a.tier || "").localeCompare(b.tier || ""),
      sortOrder: sortedInfo.columnKey === "tier" && sortedInfo.order,
      render: (tier) => (
        <span className={`tier-${tier?.toUpperCase() || "UNKNOWN"}`}>
          {tier}
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "180px",
      filters: getUniqueValues(filteredData, "type"),
      filteredValue: filteredInfo.type || null,
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#000" : "#ccc" }} />
      ),
      onFilter: (value, record) => record.type === value,
      sorter: (a, b) => (a.type || "").localeCompare(b.type || ""),
      sortOrder: sortedInfo.columnKey === "type" && sortedInfo.order,
    },

    {
      title: "Exposure",
      dataIndex: "percentage",
      key: "percentage",
      width: "100px",
      render: (text) => `${text}%`,
      sorter: (a, b) => parseFloat(a.percentage) - parseFloat(b.percentage),
      sortOrder: sortedInfo.columnKey === "percentage" && sortedInfo.order,
    },
  ];

  const handleRowClick = (record) => {
    setSelectedPlayer(record);
  };

  const tableStyle = {
    width: "90%",
    margin: "0 auto",
    marginTop: "20px",
  };

  return (
    <>
      <div style={tableStyle}>
        <Space style={{ marginBottom: 16, width: "100%" }} direction="vertical">
          <div className="search-and-buttons">
            <Input
              placeholder="Search by name or team"
              value={searchText}
              onChange={handleSearch}
              suffix={<SearchOutlined />}
              className="search-input"
            />
            <div className="button-group">
              <Button onClick={clearFilters}>Clear Filters</Button>
              <Button onClick={clearAll}>Clear All</Button>
            </div>
          </div>
        </Space>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              color: "#e91e63",
              backgroundColor: "#3f3f3f",
              borderColor: "#3f3f3f",
              headerBg: "#3f3f3f",
              headerColor: "#ffffff",
              darkItemSelectedBg: "#e91e63",
              darkItemHoverColor: "#e91e63",
              rowHoverBg: "#f83f7c",
              sortedColor: "#ffd700", // Change sorted column header color
              footerColor: "#ffffff",
              footerBg: "#ffffff",
            },
          },
        }}
      >
        <div style={tableStyle}>
          <Table
            size="small"
            dataSource={filteredData}
            columns={columns}
            onChange={handleChange}
            className="rostership-table"
            scroll={{ y: 400 }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            pagination={pagination}
            rowClassName={(record, index) =>
              `dark-mode-row ${index % 2 === 0 ? "even-row" : "odd-row"}`
            }
          />
        </div>

        {selectedPlayer && (
          <Modal
            title={`${selectedPlayer.playerName}`}
            open={!!selectedPlayer}
            onCancel={() => setSelectedPlayer(null)}
            footer={null}
          >
            <p>
              <strong>Position:</strong> {selectedPlayer.position}
            </p>
            <p>
              <strong>Team:</strong> {selectedPlayer.team}
            </p>
            <p>
              <strong>Shares:</strong> {selectedPlayer.occurrences}
            </p>
            <p>
              <strong>Exposure:</strong> {selectedPlayer.percentage}%
            </p>
            <p>
              <strong>Leagues:</strong> {selectedPlayer.leagues}
            </p>
          </Modal>
        )}
      </ConfigProvider>
    </>
  );
};

export default RostershipTable;
