import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Input, Space, Table } from "antd";
import React, { useState } from "react";
import "./../css/playertable.css";

const PlayerTable = ({ data, onRowClick, isNFL = true }) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ pageSize: 50, current: 1 });
  const [searchText, setSearchText] = useState("");

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSearchText("");
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchText("");
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const getUniqueValues = (data, key) => {
    return [...new Set(data.map((item) => item[key]))].map((value) => ({
      text: value,
      value,
    }));
  };

  const filteredData = data.filter(
    (item) =>
      item.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.last_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      title: "Pos",
      dataIndex: "position",
      key: "position",
      width: "70px",
      filters: getUniqueValues(data, "position"),
      filteredValue: filteredInfo.position || null,
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#f83f7c" : "#ffffff" }} />
      ),
      onFilter: (value, record) => record.position.includes(value),
      sorter: (a, b) => a.position.localeCompare(b.position),
      sortOrder: sortedInfo.columnKey === "position" && sortedInfo.order,
    },
    {
      title: "Name",
      key: "name",
      width: "100px",
      render: (text, record) => (
        <span className="nowrap-tier">{`${record.first_name.charAt(0)}. ${
          record.last_name
        }`}</span>
      ),
    },
    {
      title: isNFL ? "Team" : "College",
      dataIndex: isNFL ? "team" : "school",
      key: isNFL ? "team" : "school", // Update the key dynamically based on isNFL
      width: "90px",
      filters: getUniqueValues(data, isNFL ? "team" : "school"), // Adjust filters based on isNFL
      filteredValue: filteredInfo[isNFL ? "team" : "school"] || null, // Handle filteredValue dynamically
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#f83f7c" : "#ffffff" }} />
      ),
      onFilter: (value, record) =>
        isNFL ? record.team.includes(value) : record.school.includes(value), // Adjust onFilter logic
      sorter: (a, b) =>
        isNFL ? a.team.localeCompare(b.team) : a.school.localeCompare(b.school), // Adjust sorter logic
      sortOrder:
        sortedInfo.columnKey === (isNFL ? "team" : "school") &&
        sortedInfo.order, // Adjust sortOrder dynamically
    },

    {
      title: "Pos Rank",
      dataIndex: "rank",
      key: "rank",
      width: "100px",
      sorter: (a, b) => a.rank - b.rank,
      sortOrder: sortedInfo.columnKey === "rank" && sortedInfo.order,
    },
    {
      title: "Player Rank",
      dataIndex: "startuprank",
      key: "startuprank",
      width: "100px",
      sorter: (a, b) => a.startuprank - b.startuprank,
      sortOrder: sortedInfo.columnKey === "startuprank" && sortedInfo.order,
    },
    {
      title: isNFL ? "Player Tier" : "CFB Tier", // Adjust title dynamically
      dataIndex: isNFL ? "tier" : "cfbtier", // Adjust dataIndex dynamically
      key: isNFL ? "tier" : "cfbtier", // Adjust key dynamically
      width: "120px",
      filters: getUniqueValues(data, isNFL ? "tier" : "cfbtier"), // Adjust filters dynamically
      filteredValue: filteredInfo[isNFL ? "tier" : "cfbtier"] || null, // Handle filteredValue dynamically
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#f83f7c" : "#ffffff" }} />
      ),
      onFilter: (value, record) =>
        isNFL ? record.tier.includes(value) : record.cfbtier.includes(value), // Adjust onFilter logic dynamically
      render: (text) => {
        let className = "";
        if (text === "UNTOUCHABLE") {
          className = "tier-UNTOUCHABLE";
        } else if (text === "CORNERSTONE") {
          className = "tier-CORNERSTONE";
        } else if (text === "FOUNDATION") {
          className = "tier-FOUNDATION";
        } else if (text === "BASE") {
          className = "tier-BASE";
        } else if (text === "DEPTH") {
          className = "tier-DEPTH";
        } else if (text === "CLOGGER") {
          className = "tier-CLOGGER";
        } else if (text === "ELITE") {
          className = "tier-ELITE";
        } else if (text === "UPSIDE") {
          className = "tier-UPSIDE";
        } else if (text === "LOW FLOOR") {
          className = "tier-LOWFLOOR";
        } else if (text === "BUST") {
          className = "tier-BUST";
        }
        return <span className={`${className} nowrap-tier`}>{text}</span>;
      },
    },
    {
      title: isNFL ? "Player Type" : "CFB Type", // Adjust title dynamically
      dataIndex: isNFL ? "type" : "cfbtype", // Adjust dataIndex dynamically
      key: isNFL ? "type" : "cfbtype", // Adjust key dynamically
      width: "120px",
      filters: getUniqueValues(data, isNFL ? "type" : "cfbtype"), // Adjust filters dynamically
      filteredValue: filteredInfo[isNFL ? "type" : "cfbtype"] || null, // Handle filteredValue dynamically
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#f83f7c" : "#ffffff" }} />
      ),
      onFilter: (value, record) =>
        isNFL ? record.type.includes(value) : record.cfbtype.includes(value), // Adjust onFilter logic dynamically
      sorter: (a, b) =>
        isNFL
          ? a.type.localeCompare(b.type)
          : a.cfbtype.localeCompare(b.cfbtype), // Adjust sorter logic dynamically
      sortOrder:
        sortedInfo.columnKey === (isNFL ? "type" : "cfbtype") &&
        sortedInfo.order, // Adjust sortOrder dynamically
    },

    ...(isNFL
      ? [
          {
            title: "Contend Tag",
            dataIndex: "contenderbsh",
            key: "contenderbsh",
            width: "130px",
            filters: getUniqueValues(data, "contenderbsh"),
            filteredValue: filteredInfo.contenderbsh || null,
            filterIcon: (filtered) => (
              <FilterFilled
                style={{ color: filtered ? "#f83f7c" : "#ffffff" }}
              />
            ),
            onFilter: (value, record) => record.contenderbsh.includes(value),
            sorter: (a, b) => a.contenderbsh.localeCompare(b.contenderbsh),
            sortOrder:
              sortedInfo.columnKey === "contenderbsh" && sortedInfo.order,
            render: (text) => {
              let className = "";
              if (text === "BUY") {
                className = "tier-BUY";
              } else if (text === "SELL") {
                className = "tier-SELL";
              }
              return <span className={className}>{text}</span>;
            },
          },
          {
            title: "Rebuild Tag",
            dataIndex: "rebuilderbsh",
            key: "rebuilderbsh",
            width: "120px",
            filters: getUniqueValues(data, "rebuilderbsh"),
            filteredValue: filteredInfo.rebuilderbsh || null,
            filterIcon: (filtered) => (
              <FilterFilled
                style={{ color: filtered ? "#f83f7c" : "#ffffff" }}
              />
            ),
            onFilter: (value, record) => record.rebuilderbsh.includes(value),
            sorter: (a, b) => a.rebuilderbsh.localeCompare(b.rebuilderbsh),
            sortOrder:
              sortedInfo.columnKey === "rebuilderbsh" && sortedInfo.order,
            render: (text) => {
              let className = "";
              if (text === "BUY") {
                className = "tier-BUY";
              } else if (text === "SELL") {
                className = "tier-SELL";
              }
              return <span className={className}>{text}</span>;
            },
          },
        ]
      : []), // If isNFL is false, exclude these columns
    {
      title: "Draft YR",
      dataIndex: "draftyear",
      key: "draftyear",
      width: "100px",
      filters: getUniqueValues(data, "draftyear"),
      filteredValue: filteredInfo.draftyear || null,
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? "#f83f7c" : "#ffffff" }} />
      ),
      onFilter: (value, record) => record.draftyear.toString().includes(value),
      sorter: (a, b) => a.draftyear - b.draftyear,
      sortOrder: sortedInfo.columnKey === "draftyear" && sortedInfo.order,
    },
    ...(isNFL
      ? [
          {
            title: "College",
            dataIndex: "college",
            key: "college",
            width: "120px",
            sorter: (a, b) => a.college.localeCompare(b.college),
            filters: getUniqueValues(data, "college"),
            filteredValue: filteredInfo.college || null,
            onFilter: (value, record) =>
              record.college.toString().includes(value),
            filterIcon: (filtered) => (
              <FilterFilled
                style={{ color: filtered ? "#f83f7c" : "#ffffff" }}
              />
            ),
            sortOrder: sortedInfo.columnKey === "college" && sortedInfo.order,
            render: (text) => <span className="nowrap-tier">{text}</span>,
          },
        ]
      : []), // If isNFL is false, exclude these columns
  ];

  const tableStyle = {
    width: "90%",
    margin: "0 auto",
    marginTop: "20px",
    color: "#ffffff",
  };

  const handleRowClick = (record) => {
    onRowClick(record);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            color: "#e91e63",
            backgroundColor: "#e91e63",
            borderColor: "#3f3f3f",
            headerBg: "#3f3f3f",
            headerColor: "#ffffff",
            darkItemSelectedBg: "#e91e63",
            darkItemHoverColor: "#e91e63",
            rowHoverBg: "#f83f7c",
            footerColor: "#ffffff",
            footerBg: "#ffffff",
          },
        },
      }}
    >
      <div style={tableStyle}>
        <Space style={{ marginBottom: 16, width: "100%" }} direction="vertical">
          <div className="search-and-buttons">
            <Input
              placeholder="Search by name"
              value={searchText}
              onChange={handleSearch}
              suffix={<SearchOutlined />}
              className="search-input"
            />
            <div className="button-group">
              <Button onClick={clearFilters}>Clear Filters</Button>
              <Button onClick={clearAll}>Clear All</Button>
            </div>
          </div>
        </Space>
        <Table
          size="small"
          columns={columns}
          dataSource={filteredData}
          onChange={handleChange}
          rowKey="id"
          pagination={pagination}
          scroll={{ y: 400 }}
          rowClassName={(record, index) =>
            `dark-mode-row ${index % 2 === 0 ? "even-row" : "odd-row"}`
          }
          style={{ backgroundColor: "#3f3f3f" }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </div>
    </ConfigProvider>
  );
};

export default PlayerTable;
