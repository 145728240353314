import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;
const frontendToken = process.env.REACT_APP_FRONTEND_TOKEN;

// Function to call the compare endpoint and get similarities by playerid and year
export async function getSimilaritiesByYear(
  playerid,
  year,
  isNFL = true,
  sameyearnum = false
) {
  try {
    const response = await axios.get(
      `${baseURL}/api/compare/${playerid}/${year}?isNFL=${isNFL}&sameyearnum=${sameyearnum}`,
      {
        headers: {
          "X-Frontend-Token": frontendToken,
        },
      }
    );

    if (response.status === 200) {
      return { success: true, data: response.data }; // Return data on success
    } else {
      return { success: false, message: "No similar players found" };
    }
  } catch (error) {
    console.error("Error fetching similar players:", error);
    return { success: false, message: "Failed to fetch similar players" };
  }
}
