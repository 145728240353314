import { CheckCircleFilled } from "@ant-design/icons";
import React from "react";

const PaywallPage = () => {
  const userData = JSON.parse(localStorage.getItem("user"));

  const TIER_HIERARCHY = ["UPSIDE", "ELITE", "LEAGUE WINNER", "GOAT"];
  const userTierIndex = userData
    ? TIER_HIERARCHY.indexOf(userData.highestTier.replace(" PATRON", ""))
    : -1;

  const isTierDisabled = (tier) => {
    const tierIndex = TIER_HIERARCHY.indexOf(tier);
    return tierIndex <= userTierIndex;
  };

  const getButtonText = (tier) =>
    isTierDisabled(tier) ? "Unlocked" : "Upgrade";

  const checkmarkStyle = {
    color: "#f96854",
    fontSize: "20px",
    marginRight: "10px",
  };

  return (
    <div
      style={{
        backgroundColor: "#121212",
        color: "#ffffff",
        minHeight: "100vh",
        padding: "40px",
        textAlign: "center",
      }}
    >
      <h1 style={{ fontSize: "36px", marginBottom: "20px" }}>
        That page isn't available on your current plan.
      </h1>
      <p style={{ fontSize: "18px", marginBottom: "40px" }}>
        Choose the perfect plan and elevate your experience.
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        {/* UPSIDE Tier */}
        <div
          style={{
            backgroundColor: isTierDisabled("UPSIDE") ? "#3a3a3a" : "#1e1e1e",
            opacity: isTierDisabled("UPSIDE") ? 0.5 : 1,
            borderRadius: "10px",
            padding: "20px",
            width: "300px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <h2 style={{ fontSize: "24px", marginBottom: "10px" }}>UPSIDE</h2>
          <a
            href="https://www.patreon.com/c/dynastyIM/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              backgroundColor: isTierDisabled("UPSIDE") ? "#555555" : "#f96854",
              color: "#fff",
              borderRadius: "5px",
              textDecoration: "none",
              fontWeight: "bold",
              marginBottom: "20px",
              pointerEvents: isTierDisabled("UPSIDE") ? "none" : "auto",
            }}
          >
            {getButtonText("UPSIDE")}
          </a>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "20px",
              minHeight: "80px",
            }}
          >
            Thank you so much for choosing to support me! In return, I'll
            provide...
          </div>
          <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>Features:</h3>
          <ul
            style={{
              textAlign: "left",
              fontSize: "14px",
              marginBottom: "20px",
              paddingLeft: "0",
              listStyle: "none",
            }}
          >
            {[
              "Access to the Discord",
              "In-Depth Player and Prospect Breakdowns",
              "Trade Advice",
              "Access to ALL Process Guides",
              "Start/Sit Help",
              "Waiver Wire Targets",
              "DFS Discussion",
              "Game Theory Discussion",
              "Important News to Keep You in the Loop",
            ].map((item) => (
              <li
                key={item}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CheckCircleFilled style={checkmarkStyle} />
                {item}
              </li>
            ))}
          </ul>
        </div>

        {/* ELITE Tier */}
        <div
          style={{
            backgroundColor: isTierDisabled("ELITE") ? "#3a3a3a" : "#2a2a2a",
            opacity: isTierDisabled("ELITE") ? 0.5 : 1,
            borderRadius: "10px",
            padding: "20px",
            width: "300px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <h2 style={{ fontSize: "24px", marginBottom: "10px" }}>ELITE</h2>
          <a
            href="https://www.patreon.com/c/dynastyIM/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              backgroundColor: isTierDisabled("ELITE") ? "#555555" : "#f96854",
              color: "#fff",
              borderRadius: "5px",
              textDecoration: "none",
              fontWeight: "bold",
              marginBottom: "20px",
              pointerEvents: isTierDisabled("ELITE") ? "none" : "auto",
            }}
          >
            {getButtonText("ELITE")}
          </a>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "20px",
              minHeight: "80px",
            }}
          >
            You really are ELITE! This tier grants you everything in the UPSIDE
            tier as well as...
          </div>
          <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>Features:</h3>
          <ul
            style={{
              textAlign: "left",
              fontSize: "14px",
              marginBottom: "20px",
              paddingLeft: "0",
              listStyle: "none",
            }}
          >
            {[
              "Prospect Rankings",
              "Prospect Profile Tracking",
              "Recruit Rankings",
              "Recruit Profile Tracking",
              "Player Range of Outcomes",
              "Seasonal Projections",
              "Access to Previous Prospect and Recruit Profiles",
              "ALL CFB Pages on this website",
            ].map((item) => (
              <li
                key={item}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CheckCircleFilled style={checkmarkStyle} />
                {item}
              </li>
            ))}
          </ul>
        </div>

        {/* LEAGUE WINNER Tier */}
        <div
          style={{
            backgroundColor: isTierDisabled("LEAGUE WINNER")
              ? "#3a3a3a"
              : "#1e1e1e",
            opacity: isTierDisabled("LEAGUE WINNER") ? 0.5 : 1,
            borderRadius: "10px",
            padding: "20px",
            width: "300px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <h2 style={{ fontSize: "24px", marginBottom: "10px" }}>
            LEAGUE WINNER
          </h2>
          <a
            href="https://www.patreon.com/c/dynastyIM/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              padding: "10px 20px",
              backgroundColor: isTierDisabled("LEAGUE WINNER")
                ? "#555555"
                : "#f96854",
              color: "#fff",
              borderRadius: "5px",
              textDecoration: "none",
              fontWeight: "bold",
              marginBottom: "20px",
              pointerEvents: isTierDisabled("LEAGUE WINNER") ? "none" : "auto",
            }}
          >
            {getButtonText("LEAGUE WINNER")}
          </a>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "20px",
              minHeight: "80px",
            }}
          >
            You really want to take your fantasy game to the next level as a
            LEAGUE WINNER! You'll have all the same benefits as UPSIDE and ELITE
            tiers as well as...
          </div>
          <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>Features:</h3>
          <ul
            style={{
              textAlign: "left",
              fontSize: "14px",
              marginBottom: "20px",
              paddingLeft: "0",
              listStyle: "none",
            }}
          >
            {[
              "Player Rankings",
              "Player Profile Tracking",
              "Player Buy/Sell Designations",
              "Personal Breakdowns of YOUR Roster",
              "Live Draft Help",
              "ALL NFL Pages on this website",
            ].map((item) => (
              <li
                key={item}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CheckCircleFilled style={checkmarkStyle} />
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* GOAT Tier */}
      <div
        style={{
          marginTop: "40px",
          backgroundColor: isTierDisabled("GOAT") ? "#3a3a3a" : "#2a2a2a",
          opacity: isTierDisabled("GOAT") ? 0.5 : 1,
          borderRadius: "10px",
          padding: "20px",
          width: "80%",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
          margin: "40px auto",
        }}
      >
        <h2 style={{ fontSize: "28px", marginBottom: "10px" }}>GOAT</h2>
        <a
          href="https://www.patreon.com/c/dynastyIM/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "inline-block",
            padding: "15px 30px",
            backgroundColor: isTierDisabled("GOAT") ? "#555555" : "#f96854",
            color: "#fff",
            borderRadius: "5px",
            textDecoration: "none",
            fontWeight: "bold",
            marginBottom: "20px",
            pointerEvents: isTierDisabled("GOAT") ? "none" : "auto",
          }}
        >
          {getButtonText("GOAT")}
        </a>
        <div
          style={{
            fontSize: "18px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          You are the ultimate GOAT for choosing to support me, and it's beyond
          appreciated!
        </div>
        <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>Features:</h3>
        <ul
          style={{
            textAlign: "left",
            fontSize: "16px",
            marginBottom: "20px",
            paddingLeft: "0",
            listStyle: "none",
          }}
        >
          {[
            "Direct Access to ME",
            "Highly Confidential GOAT Chat",
            "Access to MY Dynasty Portfolio",
          ].map((item) => (
            <li
              key={item}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircleFilled style={checkmarkStyle} />
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PaywallPage;
