import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ requiredTier, children }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const validateAccess = async () => {
      const userData = JSON.parse(localStorage.getItem("user"));
      if (!userData) {
        setIsAuthorized(false);
        navigate("/upgrade"); // Redirect to paywall if no user session
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/auth/validate-access`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: userData.userId,
              required_tier: requiredTier,
            }),
          }
        );

        const result = await response.json();
        if (response.ok && result.allowed) {
          setIsAuthorized(true); // Allow access
        } else {
          setIsAuthorized(false); // Deny access
          navigate("/upgrade"); // Redirect to paywall
        }
      } catch (error) {
        console.error("Error validating access:", error);
        setIsAuthorized(false);
        navigate("/upgrade"); // Redirect on error
      }
    };

    validateAccess();
  }, [navigate, requiredTier]);

  if (isAuthorized === null) {
    return <div>Loading...</div>; // Show a loading indicator while validating
  }

  return isAuthorized ? children : null;
};

export default ProtectedRoute;
