const baseURL = process.env.REACT_APP_API_URL;
const frontendToken = process.env.REACT_APP_FRONTEND_TOKEN;

export async function getActivePlayersSorted(isNFL) {
  try {
    // Adding more information on the request configuration

    const response = await fetch(
      `${baseURL}/api/players/active/sorted?isNFL=${isNFL}`,
      {
        headers: {
          "X-Frontend-Token": frontendToken,
        },
      }
    );

    if (!response.ok) {
      // Logging detailed response data in case of error
      console.error("Response error details:", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const text = await response.text();

    try {
      const data = JSON.parse(text); // Try to parse the text as JSON
      return data; // Return the parsed data
    } catch (jsonError) {
      console.error("Error parsing JSON:", jsonError);
      console.error("Text that caused parsing error:", text);
      return [];
    }
  } catch (error) {
    console.error("Error in fetch operation:", error);
    return [];
  }
}

export async function fetchPositionStats(position, isNFL) {
  try {
    const response = await fetch(
      `${baseURL}/api/stats${position}?isNFL=${isNFL}`,
      {
        headers: {
          "X-Frontend-Token": frontendToken,
        },
      }
    );

    if (!response.ok) {
      console.error("Response error details:", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const text = await response.text();

    try {
      const data = JSON.parse(text);
      return data;
    } catch (jsonError) {
      console.error("Error parsing JSON:", jsonError);
      console.error("Text that caused parsing error:", text);
      return [];
    }
  } catch (error) {
    console.error("Error in fetch operation:", error);
    return [];
  }
}

export async function getPlayer(id, wantCFBStats) {
  try {
    const response = await fetch(`${baseURL}/api/playerinfo/${id}`, {
      headers: {
        "X-Frontend-Token": frontendToken,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const text = await response.text();

    try {
      const data = JSON.parse(text); // Try to parse the text as JSON
      if (!wantCFBStats) {
        // Filter out college football stats
        data.stats = data.stats.filter((stat) => !stat.iscfb);

        return data;
      }
      return data; // Return the parsed data
    } catch (jsonError) {
      console.error("Error parsing JSON:", jsonError);
      return [];
    }
  } catch (error) {
    console.error("Error fetching player info:", error);
    return [];
  }
}

export async function getPlayersByTeam(team) {
  team = team.toUpperCase();
  try {
    const response = await fetch(`${baseURL}/api/players/${team}`, {
      headers: {
        "X-Frontend-Token": frontendToken,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const text = await response.text();

    try {
      const data = JSON.parse(text); // Try to parse the text as JSON
      return data; // Return the parsed data
    } catch (jsonError) {
      console.error("Error parsing JSON:", jsonError);
      return [];
    }
  } catch (error) {
    console.error("Error fetching player info:", error);
    return [];
  }
}

export async function getAllPlayers(isNFL = true, overwrite = false) {
  try {
    const response = await fetch(
      `${baseURL}/api/players?isNFL=${isNFL}&overwrite=${overwrite}`,
      {
        headers: {
          "X-Frontend-Token": frontendToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const text = await response.text();

    try {
      const data = JSON.parse(text); // Try to parse the text as JSON
      return data; // Return the parsed data
    } catch (jsonError) {
      console.error("Error parsing JSON:", jsonError);
      return [];
    }
  } catch (error) {
    console.error("Error fetching player info:", error);
    return [];
  }
}
