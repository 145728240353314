import { jwtDecode } from "jwt-decode";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";

const PatreonRedirectHandler = () => {
  const { logIn } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      try {
        const userData = jwtDecode(token);
        logIn(userData); // Update the global user state
        window.history.replaceState({}, document.title, "/"); // Clean up the URL
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }

    navigate("/");
  }, [logIn, navigate]);

  return <p>Logging in...</p>;
};

export default PatreonRedirectHandler;
