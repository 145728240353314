import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import ComparePage from "./pages/Compare";
import HomePage from "./pages/HomePage";
import PatreonRedirectHandler from "./pages/PatreonRedirectHandler";
import PaywallPage from "./pages/PaywallPage";
import PerformancePage from "./pages/Performance";
import PlayersPage from "./pages/PlayersPage";
import ProtectedRoute from "./pages/ProtectedRoute";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import RostershipPage from "./pages/RostershipPage";
import SimilarPlayersPage from "./pages/SimilarPlayers";
import { UserProvider } from "./pages/UserContext";
import DevyPerformancePage from "./pages/devy/DevyPerformance";
import { getActivePlayersSorted, getPlayer } from "./services/playerservice";
import HeaderComponent from "./views/headercomponent"; // Adjust the path as needed

function App() {
  const [players, setPlayers] = useState([]);
  const [devy, setDevy] = useState([]);

  useEffect(() => {
    async function fetchPlayers() {
      try {
        const data = await getActivePlayersSorted(true);
        setPlayers(data);
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    }
    async function fetchDevy() {
      try {
        const data = await getActivePlayersSorted(false);
        setDevy(data);
      } catch (error) {
        console.error("Error fetching devy players:", error);
      }
    }

    fetchPlayers();
    fetchDevy();
  }, []);

  // Function to detect if the user is on a mobile device
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

  return (
    <UserProvider>
      <Router>
        <Layout players={players} devy={devy} isMobile={isMobile} />
      </Router>
    </UserProvider>
  );
}

function Layout({ players, devy, isMobile }) {
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const handleRowClick = async (record) => {
    try {
      const playerInfo = await getPlayer(record.id, true);
      setSelectedPlayer(playerInfo);
    } catch (error) {
      console.error("Error fetching player info:", error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    setSelectedPlayer(null); // Reset selected player on route change
  }, [location]);

  return (
    <>
      <HeaderComponent isMobile={isMobile} />
      <div>
        <Routes>
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/upgrade" element={<PaywallPage />} />

          <Route
            path="/players"
            element={
              <ProtectedRoute requiredTier="LEAGUE WINNER PATRON">
                <PlayersPage
                  isMobile={isMobile}
                  players={players}
                  isNFL={true}
                  handleRowClick={handleRowClick}
                  selectedPlayer={selectedPlayer}
                  setSelectedPlayer={setSelectedPlayer}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/performance"
            element={
              <ProtectedRoute requiredTier="LEAGUE WINNER PATRON">
                <PerformancePage isMobile={isMobile} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rostership"
            element={<RostershipPage isMobile={isMobile} />}
          />
          <Route path="/patreon" element={<PatreonRedirectHandler />} />
          <Route
            path="/compare"
            element={
              <ComparePage key="nfl-compare" players={players} isNFL={true} />
            }
          />
          <Route
            path="/similar"
            element={
              <SimilarPlayersPage
                key="nfl-similar"
                players={players}
                isNFL={true}
              />
            }
          />
          <Route
            path="/devy/performance"
            element={
              <ProtectedRoute requiredTier="ELITE PATRON">
                <DevyPerformancePage players={players} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/devy/players"
            element={
              <ProtectedRoute requiredTier="ELITE PATRON">
                <PlayersPage
                  isMobile={isMobile}
                  players={devy}
                  isNFL={false}
                  handleRowClick={handleRowClick}
                  selectedPlayer={selectedPlayer}
                  setSelectedPlayer={setSelectedPlayer}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path="/devy/compare"
            element={
              <ProtectedRoute requiredTier="ELITE PATRON">
                <ComparePage key="devy-compare" players={devy} isNFL={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/devy/similar"
            element={
              <ProtectedRoute requiredTier="ELITE PATRON">
                <SimilarPlayersPage
                  key="devy-similar"
                  players={devy}
                  isNFL={false}
                />
              </ProtectedRoute>
            }
          />
          <Route path="/reset-password" element={<ResetPasswordPage />} />

          <Route path="*" element={<h2>404 Not Found</h2>} />
        </Routes>
      </div>
    </>
  );
}

export default App;
