import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useState } from "react";
import calculateAge from "../utils/utils";
import "./../App.css";
import "./../css/buysellhold.css";
import "./../css/playercard.css"; // Ensure this CSS file is imported for styles
import "./../css/projections.css";
import "./../css/tier.css";
import "./../css/type.css";
import CustomTabs from "./customtabs.js"; // Import the custom Tabs component
import PlayerImage from "./playerimage.js";
import PlayerMenu from "./playermenu.tsx";
import StatsTable from "./statstable.js";
import TeamMenu from "./teammenu.tsx";

const PlayerCard = ({ player, onClose, setSelectedPlayer, isNFL }) => {
  const getBuySellHoldClass = (buysellhold) => {
    switch (buysellhold) {
      case "BUY":
        return "buy";
      case "SELL":
        return "sell";
      case "HOLD":
      default:
        return "hold";
    }
  };

  function TabContent({
    isNFL,
    player_info,
    process_stats,
    age,
    activeStats,
    isShowingCFB,
    toggleStats,
    setSelectedPlayer,
    nfl_player,
  }) {
    return (
      <>
        {isNFL && (
          <div className="sectional-big">
            <TeamMenu
              currentTeam={player_info.team}
              setSelectedPlayer={setSelectedPlayer}
            />
          </div>
        )}
        <div className="content-wrapper">
          <div style={{ width: "844px" }}>
            <div className="column-and-tall-wrapper">
              <div className="column-container">
                <div className="column">
                  {isNFL ? (
                    <div className="sectional-small">
                      <PlayerMenu
                        firstName={player_info.first_name}
                        lastName={player_info.last_name}
                        position={player_info.position}
                        team={player_info.team}
                        setSelectedPlayer={setSelectedPlayer}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ marginBottom: "35px" }}
                      className="empty-section"
                    ></div>
                  )}
                  <div className="centered-wrapper">
                    <div className="centered-player-info">
                      <div className="player-info">
                        <h1 className="position">{process_stats.rank}</h1>
                        <h1>{player_info.first_name}</h1>
                      </div>
                      <div className="player-info">
                        <h1 className="position">
                          {process_stats.startuprank}
                        </h1>
                        <h1>{player_info.last_name}</h1>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tier"
                    style={{
                      paddingTop: "20px",
                      marginBottom: "-20px",
                      marginLeft: "-15px",
                      marginTop: "-20px",
                      width: "100%",
                    }}
                  >
                    <h1
                      className={`tier ${
                        isNFL
                          ? process_stats.tier
                            ? process_stats.tier.includes(" ")
                              ? process_stats.tier
                                  .replace(/\s+/g, "")
                                  .toLowerCase()
                              : process_stats.tier.toLowerCase()
                            : ""
                          : process_stats.cfbtier
                          ? process_stats.cfbtier.includes(" ")
                            ? process_stats.cfbtier
                                .replace(/\s+/g, "")
                                .toLowerCase()
                            : process_stats.cfbtier.toLowerCase()
                          : ""
                      }`}
                    >
                      {isNFL
                        ? process_stats.tier
                          ? process_stats.tier.toUpperCase()
                          : ""
                        : process_stats.cfbtier
                        ? process_stats.cfbtier.toUpperCase()
                        : ""}
                    </h1>
                  </div>
                  <div
                    className="type"
                    style={{ marginLeft: "-15px", width: "100%" }}
                  >
                    <h1
                      className={`type ${
                        isNFL
                          ? process_stats.type
                            ? process_stats.type.toLowerCase()
                            : ""
                          : process_stats.cfbtype
                          ? process_stats.cfbtype.toLowerCase()
                          : ""
                      }`}
                    >
                      {isNFL
                        ? process_stats.type
                          ? process_stats.type.toUpperCase()
                          : ""
                        : process_stats.cfbtype
                        ? process_stats.cfbtype.toUpperCase()
                        : ""}
                    </h1>
                  </div>
                </div>
                <div className="column">
                  <div className="general-info">
                    <div className="info-row">
                      <span className="label">
                        {isNFL ? "BORN" : "RECRUIT YEAR"}
                      </span>
                      <span className="value">
                        {isNFL ? player_info.birthday : player_info.recruityear}
                      </span>
                    </div>
                    <div className="info-row">
                      <span className="label">
                        {nfl_player ? "AGE" : "YEAR"}
                      </span>
                      <span className="value">{age}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">COLLEGE</span>
                      <span className="value">{player_info.college}</span>
                    </div>
                    <div className="info-row">
                      <span className="label">DRAFT YEAR</span>
                      <span className="value">{player_info.draftyear}</span>
                    </div>
                  </div>
                  {isNFL && (
                    <div className="player-info2">
                      <div className="info-pair">
                        <div className="buildtype">REBUILDER</div>
                        <h1
                          className={`buysellhold ${getBuySellHoldClass(
                            process_stats.rebuilderbsh
                          )}`}
                        >
                          {process_stats.rebuilderbsh
                            ? process_stats.rebuilderbsh
                            : "HOLD"}
                        </h1>
                      </div>
                      <div className="info-pair">
                        <div className="buildtype">CONTENDER</div>
                        <h1
                          className={`buysellhold ${getBuySellHoldClass(
                            process_stats.contenderbsh
                          )}`}
                        >
                          {process_stats.contenderbsh
                            ? process_stats.contenderbsh
                            : "HOLD"}
                        </h1>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div style={{ textAlign: 'left', marginBottom: '10px' }}>
              <Switch
                disabled={!isNFL}
                checked={isShowingCFB}
                onChange={toggleStats}
                checkedChildren="CFB"
                unCheckedChildren="NFL"
              />
            </div> */}
            <div className="stats-table-wrapper">
              <StatsTable
                position={player_info.position}
                stats={activeStats}
                isNFL={isNFL}
              />
            </div>
          </div>
          <div className="tall">
            <PlayerImage
              player={player_info}
              player_info={player_info}
              isNFL={isNFL}
            />
          </div>
        </div>
      </>
    );
  }

  const player_info = player.player_info;
  const process_stats = player.process_stats[0];
  const projections = player.projections[0];

  const stats = player.stats;

  const cfbStats = stats.filter((stat) => stat.iscfb);
  const nflStats = stats.filter((stat) => !stat.iscfb);

  // Set the initial state based on the isNFL prop
  const [isShowingCFB, setIsShowingCFB] = useState(!isNFL);

  const toggleStats = () => {
    setIsShowingCFB(!isShowingCFB);
  };

  const activeStats = isShowingCFB ? cfbStats : nflStats;

  const age = calculateAge(player_info, isNFL);

  return (
    <div className="player-card-wrapper">
      <div className="player-card">
        <div className="button-tab-container">
          <Button
            type="primary"
            shape="circle"
            icon={<CloseOutlined style={{ color: "black" }} />}
            className="overlay-button"
            onClick={onClose}
          />
          <CustomTabs defaultActiveKey="1" width="90%">
            <TabPane tab="Info" key="1">
              <TabContent
                isNFL={isNFL}
                player_info={player_info}
                process_stats={process_stats}
                age={age}
                nfl_player={isNFL}
                activeStats={activeStats}
                isShowingCFB={isShowingCFB}
                toggleStats={toggleStats}
                setSelectedPlayer={setSelectedPlayer}
              />
            </TabPane>
            <TabPane tab="Prospect" key="5" disabled={!isNFL}>
              <TabContent
                isNFL={!isNFL} // Invert the value for the Prospect tab
                player_info={player_info}
                nfl_player={isNFL}
                process_stats={process_stats}
                age={age}
                activeStats={cfbStats}
                isShowingCFB={isShowingCFB}
                toggleStats={toggleStats}
                setSelectedPlayer={setSelectedPlayer}
              />
            </TabPane>
            <TabPane tab="Projections" key="2" disabled={!isNFL}>
              {isNFL && (
                <div className="projections">
                  <h2>2024 Projections</h2>
                  <h2> 6pt Pass TD --- PPR --- +0.5 TE Premium</h2>
                  <div className="projection-container">
                    <div className="projection1">
                      <p className="label">MED</p>
                      {projections && projections.medproj && (
                        <p className="value">{projections.medproj}</p>
                      )}
                    </div>
                    <div className="projection2">
                      <p className="label">HIGH</p>
                      {projections && projections.highproj && (
                        <p className="value">{projections.highproj}</p>
                      )}
                    </div>
                    <div className="projection1">
                      <p className="label">LOW</p>
                      {projections && projections.lowproj && (
                        <p className="value">{projections.lowproj}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </TabPane>

            <TabPane tab="ROO" key="3" disabled={!isNFL}>
              {isNFL && (
                <div
                  style={{
                    color: "white",
                    fontSize: "25px",
                    fontFamily: "Briston",
                  }}
                >
                  Coming Soon...
                </div>
              )}
            </TabPane>
          </CustomTabs>
        </div>
      </div>
    </div>
  );
};

export default PlayerCard;
