import {
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons"; // Import the Ant Design Info Icon
import { faPatreon } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfigProvider, message } from "antd";
import Modal from "antd/es/modal";
import Tooltip from "antd/es/tooltip"; // Import the Tooltip component
import React, { useEffect, useState } from "react";
import styles from "../css/LoginComponent.module.css";
import { useUser } from "../pages/UserContext";
import {
  forgotPassword,
  loginUser,
  signUpUser,
} from "../services/loginservice";
import testimonialtab from "./../assets/images/Testimonial_Tab4.png";

const LoginComponent = () => {
  const { user, logIn, logOut } = useUser(); // Replaces the local user state and uses context

  useEffect(() => {
    const handleStorageChange = () => {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        logIn(JSON.parse(storedUser)); // Update global context
      } else {
        logOut(); // Clear global context
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [logIn, logOut]);

  const logOutButton = () => {
    // Remove user data from localStorage
    localStorage.removeItem("user");

    // Clear the user state in the React Context
    logOut(); // Use logOut from the context to clear the global state

    // Optionally clear success and error messages
    setSuccessMessage(""); // Clear success messages
    setErrorMessage(""); // Clear error messages

    // Navigate the user back to the login page or refresh the state
    window.history.replaceState({}, document.title, "/");
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false); // Track the current pane
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [forgotEmail, setForgotEmail] = useState(""); // Email for forgot password workflow
  const [passwordsMatch, setPasswordsMatch] = useState(true); // New state
  const [showPassword, setShowPassword] = useState(false); // Track visibility of password
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Track visibility of confirm password
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  // Function to validate passwords on blur
  const handlePasswordBlur = () => {
    if (
      signUpPassword &&
      confirmPassword &&
      signUpPassword !== confirmPassword
    ) {
      setPasswordsMatch(false);
      message.error("Passwords do not match!"); // Show popup if passwords don't match
    } else {
      setPasswordsMatch(true);
    }
  };
  const isFormValid = email.trim() !== "" && password.trim() !== "";

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the form from reloading the page
    setErrorMessage(""); // Clear previous errors
    setSuccessMessage(""); // Clear previous success messages

    try {
      const result = await loginUser(email, password);

      if (result.id) {
        const userData = {
          email,
          highestTier: result.highest_tier, // Replace with actual tier from your backend
          userId: result.id, // Replace with actual user ID from your backend
        };

        logIn(userData); // Use logIn from context to update the user state
        setSuccessMessage("Login successful!");
      } else if (result === "INVALID_EMAIL") {
        setErrorMessage("That email does not exist.");
      } else if (result === "INVALID_PASSWORD") {
        setErrorMessage("The password is incorrect.");
      } else if (result === "MISSING_FIELDS") {
        setErrorMessage("Please fill in all required fields.");
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("An error occurred while attempting to log in.");
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleLoginPatreon = () => {
    // Redirect to Patreon login
    window.location.href =
      "https://www.patreon.com/oauth2/authorize?response_type=code&client_id=ZRhZJSuetDL2GfrLea5BJ0Fl-aGTigP4qyTvCsRp3m7X8m4S54fv_13Zgm0jm-M0&redirect_uri=https://dynasty-im-backend-ccc68ed32453.herokuapp.com/api/auth/patreon/callback&scope=identity%20identity.memberships";
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear previous errors
    setSuccessMessage(""); // Clear previous success messages

    try {
      const result = await forgotPassword(forgotEmail);

      if (result === "SUCCESS") {
        setSuccessMessage("A password reset email has been sent!");
      } else if (result === "MISSING_FIELDS") {
        setErrorMessage("Please provide your email address.");
      } else if (result === "INVALID_EMAIL") {
        setErrorMessage("No account found with that email address.");
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      console.error("Error during forgot password request:", error);
      setErrorMessage(
        "An error occurred while attempting to send the reset email."
      );
    }
  };

  const [isSignUp, setIsSignUp] = useState(false); // Track the current pane
  const [signUpEmail, setSignUpEmail] = useState(""); // Email for sign-up
  const [signUpPassword, setSignUpPassword] = useState(""); // Password for sign-up
  const [confirmPassword, setConfirmPassword] = useState(""); // Confirm password for sign-up
  const [oneTimeCode, setOneTimeCode] = useState(""); // One-time code for sign-up

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear previous errors
    setSuccessMessage(""); // Clear previous success messages

    try {
      // Call the signUpUser function with all required parameters
      const result = await signUpUser(signUpEmail, signUpPassword, oneTimeCode);

      if (result === "SUCCESS") {
        setSuccessMessage("Sign-up successful! Welcome!");
        // Redirect or perform further actions here
      } else if (result === "MISSING_FIELDS") {
        setErrorMessage("Please fill in all required fields.");
      } else if (result === "PASSWORD_MISMATCH") {
        setErrorMessage("Passwords do not match.");
      } else if (result === "INVALID_ONE_TIME_CODE") {
        setErrorMessage("The one-time code is invalid.");
      } else if (result === "EMAIL_ALREADY_EXISTS") {
        setErrorMessage("An account with this email already exists.");
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      console.error("Error during sign-up:", error);
      setErrorMessage("An error occurred while attempting to sign up.");
    }
  };

  return (
    <>
      {user ? (
        <div styles={{ backgroundColor: "#00000" }}>
          <button onClick={logOutButton}>Log Out</button>
        </div>
      ) : (
        <div styles={{ backgroundColor: "#00000" }}>
          <button
            className={styles.loginbutton}
            onClick={handleOpenModal}
            styles={{ fontSize: "93px" }}
          >
            LOGIN
          </button>
          <ConfigProvider theme={{ hashed: false }}>
            <Modal
              open={isModalVisible}
              onCancel={handleCloseModal}
              footer={null}
              styles={{
                content: {
                  backgroundColor: "#000000",
                  border: "none", // Remove border
                  padding: 0,
                  boxShadow:
                    "9px 9px 10px rgba(0, 0, 0, 0.9)" /* Add drop shadow to the container */,
                },
              }}
              closeIcon={
                <span style={{ color: "#a3a3a3", fontSize: "20px" }}>✖</span>
              }
              centered
              width={800}
            >
              <div className={styles.modalContent}>
                <div className={styles.leftPane}>
                  {isForgotPassword ? (
                    <>
                      <button
                        className={styles.backButton}
                        onClick={() => {
                          setIsForgotPassword(false);
                          setErrorMessage(""); // Clear error messages
                          setSuccessMessage(""); // Clear success messages
                        }}
                      >
                        ← Back
                      </button>
                      <h2 className={styles.headingh2}>Forgot Password</h2>
                      <p className={styles.headingp}>
                        Enter your email to reset your password:
                      </p>
                      <form onSubmit={handleForgotPasswordSubmit}>
                        <input
                          type="email"
                          placeholder="Your email address"
                          className={styles.input}
                          value={forgotEmail}
                          onChange={(e) => setForgotEmail(e.target.value)}
                          required
                        />
                        <button
                          type="submit"
                          className={styles.loginButton}
                          disabled={!forgotEmail.trim()}
                        >
                          Submit
                        </button>
                        {errorMessage && (
                          <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                        {successMessage && (
                          <p style={{ color: "green" }}>{successMessage}</p>
                        )}
                      </form>
                    </>
                  ) : isSignUp ? (
                    <>
                      <button
                        className={styles.backButton}
                        onClick={() => {
                          setIsSignUp(false);
                          setErrorMessage(""); // Clear error messages
                          setSuccessMessage(""); // Clear success messages
                        }}
                      >
                        ← Back
                      </button>
                      <h2 className={styles.headingh2}>Sign Up</h2>
                      <p className={styles.headingp}>
                        Create an account to get started:
                      </p>
                      <form onSubmit={handleSignUpSubmit}>
                        <input
                          type="email"
                          placeholder="Your email address"
                          className={styles.input}
                          value={signUpEmail}
                          onChange={(e) => setSignUpEmail(e.target.value)}
                          required
                        />
                        <div style={{ position: "relative" }}>
                          <input
                            type={showPassword ? "text" : "password"} // Toggle between text and password
                            name="password"
                            placeholder="Your password"
                            className={styles.input}
                            value={signUpPassword}
                            onChange={(e) => setSignUpPassword(e.target.value)}
                            onBlur={handlePasswordBlur} // Validate when leaving the field
                            required
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "20px",
                              top: "40%",
                              transform: "translateY(-50%)", // Ensures vertical centering
                              cursor: "pointer",
                              color: "#a3a3a3",
                              height: "20px", // Set height to match font size
                              display: "flex",
                              alignItems: "center", // Ensure the icon is vertically centered
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </span>
                        </div>
                        <div style={{ position: "relative" }}>
                          <input
                            type={showConfirmPassword ? "text" : "password"} // Toggle between text and password
                            name="confirmPassword"
                            placeholder="Confirm password"
                            className={styles.input}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onBlur={handlePasswordBlur} // Validate when leaving the field
                            required
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "20px",
                              top: "40%",
                              transform: "translateY(-50%)", // Ensures vertical centering
                              cursor: "pointer",
                              color: "#a3a3a3",
                              height: "20px", // Set height to match font size
                              display: "flex",
                              alignItems: "center", // Ensure the icon is vertically centered
                            }}
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {showConfirmPassword ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </span>
                        </div>
                        {!passwordsMatch && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "-10px",
                            }}
                          >
                            Passwords must match!
                          </p>
                        )}
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            placeholder="One-time code"
                            className={styles.input}
                            value={oneTimeCode}
                            onChange={(e) => setOneTimeCode(e.target.value)}
                            required
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "20px",
                              top: "40%",
                              transform: "translateY(-50%)", // Ensures vertical centering
                              cursor: "pointer",
                              color: "#a3a3a3",
                              height: "20px", // Set height to match font size
                              display: "flex",
                              alignItems: "center", // Ensure the icon is vertically centered
                            }}
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            <Tooltip title="This workflow is for special cases. Most users should sign up via Patreon.">
                              <InfoCircleOutlined
                                style={{
                                  marginLeft: "8px",
                                  fontSize: "16px",

                                  color: "#a3a3a3",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          </span>
                        </div>
                        <button
                          type="submit"
                          className={styles.loginButton}
                          disabled={
                            !signUpEmail.trim() ||
                            !signUpPassword.trim() ||
                            !confirmPassword.trim() ||
                            !oneTimeCode.trim() ||
                            !passwordsMatch // Disable button if passwords don't match
                          }
                        >
                          Sign Up
                        </button>
                        {errorMessage && (
                          <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                        {successMessage && (
                          <p style={{ color: "green" }}>{successMessage}</p>
                        )}
                      </form>
                    </>
                  ) : (
                    <>
                      <h2 className={styles.headingh2}>Welcome!</h2>
                      <p className={styles.headingp}>
                        Log in via Patreon to access the platform.
                      </p>
                      <button
                        className={styles.patreonButton}
                        onClick={handleLoginPatreon}
                      >
                        <FontAwesomeIcon
                          icon={faPatreon}
                          style={{
                            marginRight: "10px",
                            color: "#f96854",
                            fontSize: "20px",
                          }}
                        />
                        Patreon Login
                      </button>
                      <div className={styles.orDivider}>OR</div>
                      <form>
                        <input
                          type="email"
                          placeholder="Your email address"
                          className={styles.input}
                          value={email}
                          onChange={handleEmailChange}
                          required
                        />
                        <input
                          type="password"
                          placeholder="Your password"
                          className={styles.input}
                          value={password}
                          onChange={handlePasswordChange}
                          required
                        />
                        <div className={styles.forgotPassword}>
                          <a
                            onClick={() => setIsForgotPassword(true)}
                            style={{ cursor: "pointer" }}
                          >
                            Forgot password?
                          </a>
                        </div>
                        <button
                          type="submit"
                          className={styles.loginButton}
                          disabled={!isFormValid} // Disable the button if form is invalid
                          onSubmit={handleLogin}
                          onClick={handleLogin}
                        >
                          Log in
                        </button>
                        {errorMessage && (
                          <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                        {successMessage && (
                          <p style={{ color: "green" }}>{successMessage}</p>
                        )}
                      </form>
                      <div className={styles.signUp}>
                        Don’t have an account?{" "}
                        <a
                          onClick={() => setIsSignUp(true)}
                          style={{ cursor: "pointer" }}
                        >
                          Sign up
                        </a>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className={styles.rightPane}
                  style={{
                    backgroundImage: `url(${testimonialtab})`,
                  }}
                >
                  <h3
                    className={styles.headingh3}
                    style={{ paddingTop: "250px" }}
                  >
                    300+ users. 250+ leagues won.
                  </h3>
                  <button
                    className={styles.joinNowButton}
                    onClick={() =>
                      window.open(
                        "https://www.patreon.com/c/dynastyIM/",
                        "_blank"
                      )
                    }
                  >
                    Join Now Via Patreon
                  </button>
                </div>
              </div>
            </Modal>
          </ConfigProvider>
        </div>
      )}
    </>
  );
};

export default LoginComponent;
