import {
  faInstagram,
  faPatreon,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom";
import { useUser } from "./UserContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { getGamesForWeek } from "../services/gameservice";
import GamesDisplay from "../views/gamesdisplay";
import homepagebox1 from "./../assets/images/Homepage_Box_1.png"; // Importing the image
import homepagebox2 from "./../assets/images/Homepage_Box_2.png"; // Importing the image
import homepagebox3 from "./../assets/images/Homepage_Box_3.png"; // Importing the image
import homepagebox4 from "./../assets/images/Homepage_Box_4.png"; // Importing the image
import homepagetab from "./../assets/images/Homepage_Tab.png"; // Importing the image
import styles from "./../css/homepage.module.css"; // Import the module properly

const ACTIVE_HOURS_POLL_INTERVAL = 35000; // 35 seconds during active hours
const OFF_HOURS_POLL_INTERVAL = 120000; // 2 minutes during off hours

const HomePage = () => {
  const [gamesData, setGamesData] = useState([]);
  const [currentGameIndex, setCurrentGameIndex] = useState(0);

  const fetchGames = async () => {
    try {
      const data = await getGamesForWeek();
      setGamesData([...data]);
    } catch (error) {
      console.error("Error fetching games data:", error);
    }
  };

  const getPollingInterval = () => {
    const currentUTC = new Date().getUTCHours();
    const currentEST = (currentUTC - 5 + 24) % 24; // Adjusting to EST (UTC-5)
    return currentEST >= 2 && currentEST < 9
      ? OFF_HOURS_POLL_INTERVAL
      : ACTIVE_HOURS_POLL_INTERVAL;
  };

  useEffect(() => {
    fetchGames(); // Initial fetch

    const pollInterval = setInterval(fetchGames, getPollingInterval());
    return () => clearInterval(pollInterval); // Cleanup on unmount
  }, []);

  useEffect(() => {
    if (gamesData.length > 0) {
      const scrollInterval = setInterval(() => {
        setCurrentGameIndex((prevIndex) => (prevIndex + 1) % gamesData.length);
      }, 5000); // Every 5 seconds
      return () => clearInterval(scrollInterval);
    }
  }, [gamesData]);

  const location = useLocation();
  const { user, logIn, logOut } = useUser();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      try {
        // Decode the token to get user data
        const userData = jwtDecode(token);

        // Store the token in localStorage for session persistence
        localStorage.setItem("user", JSON.stringify(userData));

        // Update the app's user state
        logIn(userData);

        // Clean up the URL by removing the token parameter
        window.history.replaceState({}, document.title, "/");
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    } else {
      // Check localStorage for an existing session
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        logIn(JSON.parse(storedUser));
      }
    }
  }, [logIn, location]);

  return (
    <div className={styles.homepage}>
      {/* Add the image at the top */}
      <img
        src={homepagetab}
        alt="Performance Overview"
        className={styles.performanceImage}
      />

      <div className={styles.additionalSection}>
        <div className={styles.sectionTitle}>
          <h2>
            ABOUT <span className={styles.gold}>DYNASTYIM</span>
          </h2>
        </div>

        <div className={styles.contentContainer}>
          {/* Left Column: Title and Text */}
          <div className={styles.textSection}>
            <h3>helping you gain an edge in the fantasy football world</h3>
            <p>
              We strive to be your ultimate fantasy football resource, offering
              tools to track current stats, project future performance, and
              compare players side by side. Dive into detailed player profiles
              and unique metrics designed to help you make smarter decisions and
              dominate your league.
            </p>
          </div>

          {/* Middle Column: Specializations */}
          <div className={styles.specialization}>
            <h4>join</h4>
            <h4>our community:</h4>
            <p>
              Connect with fellow managers, share strategies, and stay ahead of
              the competition through our vibrant fantasy football network.
            </p>
            <a
              href="https://www.patreon.com/dynastyIM"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.joinButton}
            >
              Join Now
            </a>
          </div>

          {/* Right Column: GamesDisplay */}
          <div className={styles.gamesDisplaySection}>
            {gamesData.length > 0 ? (
              <GamesDisplay
                gamesData={gamesData}
                currentGameIndex={currentGameIndex}
              />
            ) : (
              <p>Loading games...</p>
            )}
          </div>
        </div>
      </div>

      <div className={styles.imageGridSection}>
        <div className={styles.sectionFooter}>
          <h2>
            OUR <span className={styles.gold}>TOOLS</span>
          </h2>
        </div>
        <div className={styles.imageGrid}>
          {[
            { image: homepagebox1, link: "/players" },
            { image: homepagebox2, link: "/performance" },
            { image: homepagebox3, link: "/compare" },
            { image: homepagebox4, link: "/similar" },
          ].map((item, index) => (
            <div className={styles.gridImage} key={index}>
              <a href={item.link} className={styles.imgWrapper}>
                <img src={item.image} alt={`Grid image ${index + 1}`} />
              </a>
            </div>
          ))}
        </div>
      </div>

      <section className={styles.footer}>
        <h2>
          FOLLOW ME ON <span>ALL</span> SOCIALS RIGHT HERE
        </h2>
        <div className={styles.socialLinks}>
          <a
            href="https://twitter.com/dynasty_im"
            className={`${styles.socialIcon} ${styles.twitter}`}
            target="_blank"
            rel="noopener noreferrer"
            name="twitter"
            aria-label="twitter"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href="https://patreon.com/dynastyIM"
            className={`${styles.socialIcon} ${styles.patreon}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="patreon"
          >
            <FontAwesomeIcon icon={faPatreon} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCB4Jf-CWYkcfbMzjFs13ZaA"
            className={`${styles.socialIcon} ${styles.youtube}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="youtube"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a
            href="https://www.tiktok.com/@dynastyim"
            className={`${styles.socialIcon} ${styles.tiktok}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="tiktok"
          >
            <FontAwesomeIcon icon={faTiktok} />
          </a>
          <a
            href="https://www.instagram.com/dynasty_im/"
            className={`${styles.socialIcon} ${styles.instagram}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="instagram"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
