export const qb = [
    {
      title: 'PPG',
      dataIndex: 'ppg',
      key: 'ppg',
      width: '8%',
      sorter: (a, b) => a.ppg - b.ppg,
    },
    {
      title: 'PPPG',
      dataIndex: 'pppg',
      key: 'pppg',
      width:'8%',
      sorter: (a, b) => a.pppg - b.pppg,
    },
    {
      title: 'RPPG',
      dataIndex: 'rppg',
      key: 'rppg',
      width: '8%',
      sorter: (a, b) => a.rppg - b.rppg,
    },
    {
      title: 'aY/A',
      dataIndex: 'aya',
      key: 'aya',
      width: '8%',
      sorter: (a, b) => a.aya - b.aya,
    },
    {
      title: 'PASSTD%',
      dataIndex: 'passtdpercent',
      key: 'passtdpercent',
      width: '11%',
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.passtdpercent - b.passtdpercent,
    },
    {
      title: 'PASSINT%',
      dataIndex: 'passintpercent',
      key: 'passintpercent',
      width: '11%',
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.passintpercent - b.passintpercent,
    },
    {
      title: 'PA',
      dataIndex: 'passattempts',
      key: 'passattempts',
      width: '11%',
      sorter: (a, b) => a.passattempts - b.passattempts,
    },
    {
      title: 'YPA',
      dataIndex: 'ypa',
      key: 'ypa',
      width: '8%',
      sorter: (a, b) => a.ypa - b.ypa,
    },
    {
      title: 'CARRY%',
      dataIndex: 'carrypercent',
      key: 'carrypercent',
      width: '11%',
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.carrypercent - b.carrypercent,
    },
    {
      title: 'YPTRA',
      dataIndex: 'yptra',
      key: 'yptra',
      width: '11%',
      sorter: (a, b) => a.yptra - b.yptra,
    },
    {
      title: 'RUSHTD%',
      dataIndex: 'rushtdpercent',
      key: 'rushtdpercent',
      width: '11%',
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.rushtdpercent - b.rushtdpercent,
    },
  ];


  export const rb = [
    {
      title: 'PPG',
      dataIndex: 'ppg',
      key: 'ppg',
      width: '10%',
      sorter: (a, b) => a.ppg - b.ppg,
    },
    {
      title: 'RUSH PPG',
      dataIndex: 'rushppg',
      key: 'rushppg',
      width: '15%',
      sorter: (a, b) => a.rushppg - b.rushppg,
    },
    {
      title: 'RECEIVE PPG',
      dataIndex: 'receivingppg',
      key: 'receivingppg',
      width: '15%',
      sorter: (a, b) => a.receivingppg - b.receivingppg,
    },
    {
      title: 'CARRY%',
      dataIndex: 'carrypercent',
      key: 'carrypercent',
      width: '10%',
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.carrypercent - b.carrypercent,
    },
    {
      title: 'YPTRA',
      dataIndex: 'yptra',
      key: 'yptra',
      width: '14%',
      sorter: (a, b) => a.yptra - b.yptra,
    },
    {
      title: 'RUSH TD MS',
      dataIndex: 'rushtdms',
      key: 'rushtdms',
      width: '14%',
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.rushtdms - b.rushtdms,
    },
    {
      title: 'TARGET SHARE',
      dataIndex: 'targetshare',
      key: 'targetshare',
      width: '16%',
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.ts - b.ts,
    },
    {
      title: 'YPTPA',
      dataIndex: 'yptpa',
      key: 'yptpa',
      width: '10%',
      sorter: (a, b) => a.yptpa - b.yptpa,
    },
    
  ];


  export const wr = [
    {
      title: 'PPG',
      dataIndex: 'ppg',
      key: 'ppg',
      width: '12%',
      sorter: (a, b) => a.ppg - b.ppg,
    },
    {
      title: 'TARGET SHARE',
      dataIndex: 'targetshare',
      key: 'targetshare',
      width: '22%',
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.ts - b.ts,
    },
    {
      title: 'YPTPA',
      dataIndex: 'yptpa',
      key: 'yptpa',
      width: '12%',
      sorter: (a, b) => a.yptpa - b.yptpa,
    },
    {
      title: 'TARGET RATE',
      dataIndex: 'targetrate',
      key: 'targetrate',
      render: (text) => `${text}%`, // Append percentage sign
      width: '22%',
      sorter: (a, b) => a.targetrate - b.targetrate,
    },
    {
      title: 'YPRR',
      dataIndex: 'yprr',
      key: 'yprr',
      width: '16%',
      sorter: (a, b) => a.yppr - b.yppr,
    },
  ]

  export const te = [
    {
      title: 'PPG',
      dataIndex: 'ppg',
      key: 'ppg',
      width: '12%',
      sorter: (a, b) => a.ppg - b.ppg,
    },
    {
      title: 'TARGET SHARE',
      dataIndex: 'targetshare',
      key: 'targetshare',
      width: '22%',
      render: (text) => `${text}%`, // Append percentage sign
      sorter: (a, b) => a.ts - b.ts,
    },
    {
      title: 'YPTPA',
      dataIndex: 'yptpa',
      key: 'yptpa',
      width: '12%',
      sorter: (a, b) => a.yptpa - b.yptpa,
    },
    {
      title: 'TARGET RATE',
      dataIndex: 'targetrate',
      key: 'targetrate',
      render: (text) => `${text}%`, // Append percentage sign
      width: '22%',
      sorter: (a, b) => a.targetrate - b.targetrate,
    },
    {
      title: 'YRPR',
      dataIndex: 'yprr',
      key: 'yprr',
      width: '16%',
      sorter: (a, b) => a.yppr - b.yppr,
    },
  ]
  