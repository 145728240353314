// src/services/authservice.js

const baseURL = process.env.REACT_APP_API_URL;
const frontendToken = process.env.REACT_APP_FRONTEND_TOKEN;

export async function loginUser(email, password) {
  try {
    const response = await fetch(`${baseURL}/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Frontend-Token": frontendToken,
      },
      body: JSON.stringify({ email, password }),
    });

    const responseData = await response.json();
    // Handle known error codes
    if (responseData.error) {
      switch (responseData.error) {
        case "INVALID_EMAIL":
          return "INVALID_EMAIL";
        case "INVALID_PASSWORD":
          return "INVALID_PASSWORD";
        case "MISSING_FIELDS":
          return "MISSING_FIELDS";
        default:
          return "UNKNOWN_ERROR"; // Catch unexpected errors
      }
    }

    // If no errors, assume login is successful
    return responseData.user;
  } catch (error) {
    console.error("Error in login operation:", error);
    throw error; // Propagate the error for the caller to handle
  }
}

export async function signUpUser(email, password, oneTimeCode) {
  try {
    const response = await fetch(`${baseURL}/api/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Frontend-Token": frontendToken,
      },
      body: JSON.stringify({
        email,
        password,
        one_time_code: oneTimeCode,
      }),
    });

    const responseData = await response.json();

    // Handle known error codes
    if (responseData.error) {
      switch (responseData.error) {
        case "MISSING_FIELDS":
          return "MISSING_FIELDS";
        case "PASSWORD_MISMATCH":
          return "PASSWORD_MISMATCH";
        case "INVALID_ONE_TIME_CODE":
          return "INVALID_ONE_TIME_CODE";
        case "EMAIL_ALREADY_EXISTS":
          return "EMAIL_ALREADY_EXISTS";
        default:
          return "UNKNOWN_ERROR"; // Catch unexpected errors
      }
    }

    // If no errors, return success
    if (responseData.message === "SIGNUP_SUCCESS") {
      return "SUCCESS";
    }

    return "UNKNOWN_ERROR"; // Fallback for unexpected cases
  } catch (error) {
    console.error("Error in signup operation:", error);
    throw error; // Propagate the error for the caller to handle
  }
}

export async function forgotPassword(email) {
  try {
    const response = await fetch(`${baseURL}/api/auth/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Frontend-Token": frontendToken,
      },
      body: JSON.stringify({ email }),
    });

    const responseData = await response.json();

    // Handle known error codes
    if (responseData.error) {
      switch (responseData.error) {
        case "MISSING_FIELDS":
          return "MISSING_FIELDS";
        case "INVALID_EMAIL":
          return "INVALID_EMAIL";
        default:
          return "UNKNOWN_ERROR"; // Catch unexpected errors
      }
    }

    // If no errors, assume the email was sent successfully
    if (responseData.message === "Password reset email sent successfully.") {
      return "SUCCESS";
    }

    return "UNKNOWN_ERROR"; // Fallback for unexpected cases
  } catch (error) {
    console.error("Error in forgot password operation:", error);
    throw error; // Propagate the error for the caller to handle
  }
}
