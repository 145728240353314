import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import "../css/resetpassword.css";

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const isFormValid =
    newPassword.trim() !== "" && newPassword === confirmPassword;

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    setErrorMessage("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setErrorMessage("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid) {
      setErrorMessage("Passwords do not match or are empty.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, newPassword }),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        setSuccessMessage("Password reset successfully! You can now log in.");
      } else {
        setErrorMessage(responseData.error || "An unexpected error occurred.");
      }
    } catch (error) {
      setErrorMessage(
        "An error occurred while attempting to reset your password."
      );
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-card">
        <h2 className="reset-password-heading">Reset Your Password</h2>
        {errorMessage && <p className="reset-password-error">{errorMessage}</p>}
        {successMessage && (
          <p className="reset-password-success">{successMessage}</p>
        )}
        <form onSubmit={handleFormSubmit} className="reset-password-form">
          <input
            type="password"
            placeholder="Enter new password"
            value={newPassword}
            onChange={handlePasswordChange}
            required
            className="reset-password-input"
          />
          <input
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
            className="reset-password-input"
          />
          <button
            type="submit"
            disabled={!isFormValid}
            className="reset-password-button"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
